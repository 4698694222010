<template>
  <a-form :form="form.fc" class="mt-3" v-bind="formItemLayout">
    <a-form-item :label="$t('network.text_205', [$t('dictionary.project')])" class="mb-0">
      <domain-project :fc="form.fc" :decorators="decorators" :domain.sync="domain" :project.sync="project" :labelInValue="false" />
    </a-form-item>
    <a-form-item :label="$t('network.text_21')">
      <a-input v-decorator="decorators.name" :placeholder="$t('validator.lbCreateName')" />
    </a-form-item>
    <a-form-item :label="$t('common.description')">
      <a-textarea :auto-size="{ minRows: 1, maxRows: 3 }" v-decorator="decorators.description" :placeholder="$t('common_367')" />
    </a-form-item>
    <area-selects
      v-bind="areaParams"
      filterBrandResource="loadbalancer_engine"
      @providerFetchSuccess="providerFetchSuccess"
      @cloudregionFetchSuccess="cloudregionFetchSuccess"
      @zoneFetchSuccess="zoneFetchSuccess"
      @change="handleChange"
      class="mb-0" />
      <!-- 规格 -->
    <a-form-item :label="$t('network.text_268')">
      <base-select
        v-decorator="decorators.loadbalancer_spec"
        :options="specOpts"
        :select-props="{ placeholder: $t('network.text_269') }"
        @change="handleSpecChange" />
    </a-form-item>
    <a-form-item :label="$t('compute.text_266')" v-if="isAws">
      <a-radio-group v-decorator="decorators.instance_type">
        <a-radio-button v-for="item in instanceOpts" :value="item.key" :label="item.label" :key="item.key">{{ item.label }}</a-radio-button>
      </a-radio-group>
    </a-form-item>
    <a-form-item :label="$t('network.text_16')">
      <a-radio-group v-decorator="decorators.address_type">
        <a-radio-button value="internet">{{$t('network.text_270')}}</a-radio-button>
        <a-radio-button value="intranet">{{$t('network.text_271')}}</a-radio-button>
      </a-radio-group>
    </a-form-item>
    <a-form-item :label="$t('network.text_272')">
      <charge-type-radio :decorators="decorators" :maxBindWidth="maxBindWidth" />
    </a-form-item>
    <a-form-item :label="$t('network.text_273')">
      <a-radio-group v-decorator="decorators.ip">
        <a-radio-button value="ipv4">IPv4</a-radio-button>
      </a-radio-group>
    </a-form-item>
    <!-- <a-form-item :label="$t('common_711')" v-if="isShowCloudprovider">
      <base-select
        v-decorator="decorators.manager"
        resource="cloudproviders"
        need-params
        filterable
        :params="cloudproviderParams"
        :select-props="{ placeholder: $t('common_588') }" />
    </a-form-item> -->
    <a-form-item :label="$t('cloudenv.text_7')">
      <a-row :gutter="9">
        <a-col :span="12">
          <oc-select
            width="100%"
            v-decorator="decorators.vpc"
            show-status
            :status-desc="$t('compute.vpc_status_desc')"
            resource="vpcs"
            label="VPC"
            :formatter="vpcFormatter"
            :params="vpcParams"
            :sort="(arr) => arr.sort((a, b) => a.network_count > b.network_count ? -1 : 1)"
            :placeholder="$t('compute.text_194')"
            @selectChange="handleVpcChange" />
        </a-col>
        <a-col :span="12" v-if=" form.fd.address_type === 'intranet'">
          <base-select
            v-decorator="decorators.network"
            resource="networks"
            need-params
            filterable
            :item.sync="networkObj"
            :params="networkParams"
            :select-props="{ placeholder: $t('network.text_275') }" />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item :label="$t('network.text_221')" v-if="form.fd.address_type === 'internet' && showEip">
      <base-select
        v-decorator="decorators.eip.eip"
        resource="eips"
        need-params
        :params="eipParams"
        :showSync="true"
        :select-props="{ placeholder: $t('network.text_278') }" />
        <div slot="extra">{{$t('system.text_439')}}<help-link href="/eip">{{$t('system.text_440')}}</help-link>
        </div>
    </a-form-item>
    <a-form-item :label="$t('table.title.tag')">
      <tag v-decorator="decorators.__meta__" :allowNoValue="false" />
    </a-form-item>
  </a-form>
</template>

<script>
import * as R from 'ramda'
import ChargeTypeRadio from '@/sections/ChargeTypeRadio'
import { LB_SPEC } from '@Network/views/lb/constants'
import AreaSelects from '@/sections/AreaSelects'
import lbCreate from './mixin.js'
import { HYPERVISORS_MAP } from '@/constants'

export default {
  name: 'LbAliyunCreate',
  components: {
    ChargeTypeRadio,
    AreaSelects,
  },
  mixins: [lbCreate],
  data () {
    return {
      specOpts: [],
      instanceOpts: LB_SPEC.aws,
      showEip: true,
      maxBindWidth: 100,
    }
  },
  computed: {
    eipParams () {
      let params = {}
      if (this.form.fd.cloudregion && !R.isEmpty(this.scopeParams)) {
        params = { ...this.scopeParams, usable: true }
        params.area_mapping_id = this.form.fd.cloudregion
        params.replace_list_key = 'region'
        if (this.isAdminMode || this.isDomainMode) {
          params.project = this.form.fd.project
        }
      }
      if (!R.isEmpty(this.vpcObj) && this.vpcObj.manager_id) {
        params.manager_id = this.vpcObj.manager_id
      }
      return params
    },
  },

  methods: {
    // 区域变化时的操作
    handleChange (item) {
      if (item.cloudregion?.id) {
        this.getSlbSkus(item.cloudregion.id)
      }
      // console.log('handleAreaChange item', item)
      const provider = item.cloudregion?.value?.provider
      if (provider && HYPERVISORS_MAP.qcloud.key === provider.toLowerCase()) {
        this.showEip = false
      } else {
        this.showEip = true
      }
    },
    handleSpecChange (item) {
      // console.log('handleSpecChange item', item)
      if (item) {
        const spec = this.specOpts.find(s => s.key === item)
        // console.log('spec', spec)
        if (spec) {
          this.maxBindWidth = spec?.bandwidth_cap || 100
        }
      }
    },
    // 获取slb的规格
    getSlbSkus (id) {
      this.form.fc.resetFields(['loadbalancer_spec'])
      this.$http({
        method: 'GET',
        url: '/v1/resource_slb_skus',
        params: {
          area_mapping_id: id,
        },
      }).then(res => {
        if (res.status === 200) {
          this.specOpts = res.data.data.map(item => {
            return {
              key: item.specs_type,
              label: item.specs_name,
              bandwidth_cap: item.bandwidth_cap || 100,
            }
          })
        }
      })
    },
    providerFetchSuccess (list) {
      const showProvider = ['Aliyun', 'Aws', 'Qcloud', 'Huawei']
      if (list && list.length > 0) {
        return list.filter(item => {
          const { name } = item
          return showProvider.indexOf(name) > -1
        })
      }
      return list
    },
    cloudregionFetchSuccess (list) {
      // const showProvider = ['Aliyun', 'Aws', 'Qcloud', 'Huawei']
      // if (list && list.length > 0) {
      //   return list.filter(item => {
      //     const { provider } = item
      //     return showProvider.indexOf(provider) > -1
      //   })
      // }
      return list
    },
    zoneFetchSuccess (list) {
      const showProvider = ['Aliyun', 'Aws', 'Qcloud', 'Huawei']
      if (list && list.length > 0) {
        return list.filter(item => {
          const { provider } = item
          return showProvider.indexOf(provider) > -1
        })
      }
      return list
    },
    async submit () {
      try {
        const values = await this.form.fc.validateFields()
        if (!this.validateIp()) throw Error(this.$t('network.text_276'))
        if (!R.isEmpty(this.vpcObj)) {
          values.manager = this.vpcObj.manager
        }
        if (this.isAdminMode) {
          values.admin = true
        } else {
          delete values.domain
        }
        if (values.charge_type === 'bandwidth') {
          values.egress_mbps = values.bandwidth
        }
        if (this.isAws) {
          values.loadbalancer_spec = values.instance_type
          delete values.instance_type
        }
        // 新建时，如果为公网，需要加这个字段
        if (values.address_type === 'internet') {
          values.replace_list_key += ',manager'
        }
        return values
      } catch (error) {
        throw error
      }
    },
  },
}
</script>
